<template>
  <q-layout
    view="hHh LpR fFf"
    class="bg-light-grey"
  >
    <q-page-container>
      <q-page>
        <div class="row absolute-full items-center">
          <div class="col-lg-2" />
          <div class="col col-md-4 col-lg-3 full-height q-pa-lg" style="background-color: white; color: #4DA23E">
            <img :src="require('assets/bp-logo.svg')" height="80px" class="q-mb-md q-pl-lg cursor-pointer" @click="$router.push({path: '/'})">
            <h4 class="text-center q-mb-md">
              {{ $t('bp_mobility_hub').toLowerCase() }}
            </h4>
            <h6 class="text-center q-mb-lg q-px-lg">
              {{ $t('login.bp.plan_your_route') }}
            </h6>
            <router-view />
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>

export default {
  name: 'LayoutLogin'
}
</script>

<style lang="stylus" scoped>
  html
    background-color white
  .q-page
    background-image url('~assets/bp-login-bg.jpg')
    background-size: cover
    background-position: top
  .login-layout
    min-width 425px
    background-color #51abe2
    &:before,
    &:after
      content ''
      position fixed
      top 0
      bottom 0
      left 0
      right 0
      z-index 0
    &:before
      background-position top right
      background-image linear-gradient(45deg, #2a98db 0%, #51abe200 100%);
      background-repeat repeat-y
    &:after
      background-image url('~assets/worldmapwithpoints.svg')
      background-position top center
      background-repeat no-repeat
      background-size 1024px
    >>> .q-btn.text-white
      color #fff !important
  .container
  .worldmap
    top 0
    z-index -1
    opacity .6
</style>
